import { observer } from "mobx-react-lite";
import React from "react";

import { useTranslation } from "../../shared/translate/NextI18next";
import { Button } from "../Button/Button";

interface Props {
  readonly isDisable?: boolean;
  readonly deleteBtnText: string;
  onClose(): void;
  onDelete(): void;
}

export const ModalDeleteButtons = observer((props: Props) => {
  const { t } = useTranslation("ModalDeleteDeck");
  return (
    <>
      <Button
        driver={{
          type: "lighter",
          dataCy: "ModalDelete-CancelBtn",
          width: "134px",
          onClick: props.onClose,
        }}
      >
        {t("modaldeletedeck.button.cancel")}
      </Button>

      <Button
        driver={{
          type: "danger",
          dataCy: "ModalDelete-DeleteBtn",
          width: "134px",
          fontWeight: 600,
          disabled: props.isDisable,
          onClick: props.onDelete,
        }}
      >
        {props.deleteBtnText}
      </Button>
    </>
  );
});
