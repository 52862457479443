import styled, { keyframes } from "styled-components";

import {
  BREAK_POINT_ACTIVE_DARK_MODE,
  largeScreenUpperLimit,
  mediumScreenUpperLimit,
  mobileMaxHeight,
  smallScreenUpperLimit,
  xLargeScreenLowerLimit,
} from "../../shared/breakpoints";
import {
  etBlack,
  etBlue,
  etGreen,
  etGreenLight,
  etOrange,
  etPink,
  etRed,
  etWhite,
} from "../../shared/colors";
import { NAVBAR_TOP_HEIGHT } from "../../shared/constants";
import zIndex from "../../shared/zIndex";

namespace sc {
  export const betaLabelAnimation = keyframes`
  0% {
    color: ${etGreen};
  }
  20% {
    color: ${etOrange};
  }
  40% {
    color: ${etPink};
  }
  60% {
    color: ${etBlue};
  }
  80% {
    color: ${etRed};
  }
`;

  export const NavbarTopContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: ${NAVBAR_TOP_HEIGHT}px; // to display correctly on safari
    height: ${NAVBAR_TOP_HEIGHT}px;
    background-color: ${etWhite};
    font-family: Roboto;
    position: sticky;
    top: 0;
    left: 0;
    z-index: ${zIndex.NAVBAR};
    @media (max-width: ${BREAK_POINT_ACTIVE_DARK_MODE}) {
      background-color: ${(props) =>
        props.theme.isMobileDevice && props.theme.themeStyle.defaultBackground};
      /* To force the browser to use hardware acceleration so it can access the device’s graphical processing unit (GPU) to make pixels fly. The point is to make sure nav bar top not disappear when scrolling on some devices. */
      transform: translateZ(0);
      -moz-transform: translatez(0);
      -ms-transform: translatez(0);
      -o-transform: translatez(0);
      -webkit-transform: translateZ(0);
    }
    // to detect mobile landscape
    @media (orientation: landscape) and (hover: none) and (pointer: coarse) and (max-width: ${mobileMaxHeight}) {
      background-color: ${etBlack};
    }
    &.mLandscape {
      background-color: ${etBlack};
    }
  `;

  export const Navbar = styled.nav`
    padding: 0.5rem 0;
    display: flex;
    justify-content: space-between;
    position: unset;
    @media (min-width: ${xLargeScreenLowerLimit}) {
      width: calc(328px * 4 + 24px * 3);
    }
    @media (max-width: ${largeScreenUpperLimit}) {
      width: calc(328px * 3 + 24px * 2);
    }
    @media (max-width: ${mediumScreenUpperLimit}) {
      width: calc(328px * 2 + 24px);
    }
    @media (max-width: ${smallScreenUpperLimit}) {
      width: 100%;
      padding-left: 12px;
      padding-right: 12px;
    }
  `;

  export const NavbarLeft = styled.div<{
    shouldRenderCenteredSearchBar: boolean;
  }>`
    display: flex;
    justify-content: center;
    flex: 1;
    @media (max-width: ${smallScreenUpperLimit}) {
      max-width: 166px;
    }
    @media (max-width: ${mediumScreenUpperLimit}) {
      ${(props) => !props.shouldRenderCenteredSearchBar && `flex: unset`};
    }
  `;

  export const TheNavbarLinkItemsContainer = styled.div`
    display: flex;
    @media (max-width: ${smallScreenUpperLimit}) {
      display: none;
    }
  `;

  export const TheNavbarRightSideContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    flex: 1;
  `;

  export const BetaLabel = styled.span`
    position: absolute;
    top: 26px;
    left: 187px;
    font-size: 0.8rem;
    display: flex;
    height: 7px;
    align-items: flex-end;
    color: ${etGreenLight};
    z-index: -1;
    animation: ${betaLabelAnimation} 10s infinite;
    @media (max-width: 395px) {
      display: none;
    }
    @media (max-width: 767.98px) {
      position: absolute;
      top: 1.625rem;
      left: 9.45rem;
    }
  `;

  export const LogoNormalMode = styled.div`
    display: flex;
  `;

  export const LogoDarkMode = styled.div`
    display: none;
    height: 32px;
    align-items: center;
  `;

  export const Logo = styled.a`
    margin-right: auto;
    position: relative;
    display: block;
    height: 40px;
    width: 40px;
    background-image: url(${require("../../assets/logo/m2-logo-black.png")});
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;

    @media (max-width: ${smallScreenUpperLimit}) {
      background-image: url(${require("../../assets/logo/m2-logo-green.png")});
      height: 32px;
      width: 32px;
    }
  `;

  export const Link = styled.span`
    cursor: pointer;
  `;

  export const CenterItems = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: ${mediumScreenUpperLimit}) {
      display: none;
    }
  `;

  export const SearchReserve = styled.div`
    margin: 0 auto 0 0;
    display: flex;
  `;
}

export default sc;
